import React, { useState, useEffect } from "react";
import { Button, CircularProgress, Snackbar, TextField, Switch, Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Web3 from "web3";
import { GOERLI, SEPOLIA, ETHEREUMMAINNET, BINANCEMAINNET, BINANCETESTNET, POLYGONMAINNET, POLYGONTESTNET } from './chains';
// import { ethers, providers, ContractFactory } from 'ethers';

import EthereumContractABI from '../ABIs/SpozzClub_ABI.json';
import BinanceContractABI from '../ABIs/SpozzClubBEP20_ABI.json';
import PolygonContractABI from '../ABIs/SpozzClubPOLY20_ABI.json';
import EthereumContractBYTECODE from '../ABIs/SpozzClub_bytecode.json';
import BinanceContractBYTECODE from '../ABIs/SpozzClubBEP20_bytecode.json';
import PolygonContracBYTECODE from '../ABIs/SpozzClubPOLY20_bytecode.json';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    progress: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1),
    },
}));

let web3Provider = '';
let contractAddress = '';

const SpozzClubDeploymentPage = ({setSC_aaa, setSC_NetworkId, setSpozzAdminContractAddress}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [tokenName, setTokenName] = useState("Spozz Club");
    const [tokenSymbol, setTokenSymbol] = useState("SPZZ");
    const [numDecimals, setNUMDecimals] = useState(0);
    const [tokenTotalSupply, setTokenTotalSupply] = useState(10000000000);
    const [tokenMaxMint, setTokenMaxMint] = useState(10000000000);
    const [tokenLiquidityPool, setTokenLiquidityPool] = useState(0);
    const [destroyAfterBurnFlag, setDestroyAfterBurnFlag] = useState(false);
    const [assignAfterBurnFlag, setAssignAfterBurnFlag] = useState(false);
    const [allowBridgingOfTokensFlag, setAllowBridgingOfTokensFlag] = useState(false);
    const [tokenBurnLimit, setTokenBurnLimit] = useState(0);
    const [chain, setChain] = useState(5);
    const [walletInfo, setWalletInfo] = useState("");
    const [privateKey, setPrivateKey] = useState("");
    const [gasLimit, setGasLimit] = useState(1500000);

    // const [isMainnet, setIsMainnet] = useState(false);
    
    useEffect(() => {
        const getWalletInfo = async () => {
            if (window.ethereum) {
                const web3 = new Web3(window.ethereum);
                try {
                    await window.ethereum.enable();
                    const accounts = await web3.eth.getAccounts();
                    const chainId = await web3.eth.getChainId();
                    setWalletInfo(`The presently connected Wallet is: ${accounts[0]} | It is on Chain ID: ${chainId}`);
                    setSC_aaa(accounts[0]);
                    setSC_NetworkId(chainId);
                    setChain(chainId);
                } catch (error) {
                    console.error(error);
                }
            }
        };

        getWalletInfo();

        window.ethereum.on('accountsChanged', getWalletInfo);
        window.ethereum.on('chainChanged', getWalletInfo);

        return () => {
            window.ethereum.removeListener('accountsChanged', getWalletInfo);
            window.ethereum.removeListener('chainChanged', getWalletInfo);
        };
    }, []);

    // useEffect(() => {
    //     if (chain === 1) {
    //         web3Provider = ETHEREUMMAINNET;
    //     } else if(chain === 5){
    //         web3Provider = GOERLI;
    //     } else if(chain === 56){
    //         web3Provider = BINANCEMAINNET;
    //     } else if(chain === 97){
    //         web3Provider = BINANCETESTNET;
    //     } else if(chain === 137){
    //         web3Provider = POLYGONMAINNET;
    //     } else if(chain === 80001){
    //         web3Provider = POLYGONTESTNET;
    //     }else {
    //         web3Provider = SEPOLIA;
    //     };
    // }, [chain]);
    
    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
        setSpozzAdminContractAddress(contractAddress);
    };
    
    const handleTokenTotalSupplyChange = (event) => {
        const newValue = parseInt(event.target.value);
        if (!isNaN(newValue) && newValue >= 0 && newValue <= 100000000000){
            setTokenTotalSupply(newValue);
        }
    };
    
    const handleTokenMaxMintChange = (event) => {
        const newValue = parseInt(event.target.value);
        if (!isNaN(newValue) && newValue >= 0 && newValue <= 100000000000) {
            setTokenMaxMint(newValue);
        }
    };
    
    const handleTokenLiquidityPoolChange = (event) => {
        const newValue = parseInt(event.target.value);
        if (!isNaN(newValue) && newValue >= 0 && newValue <= 1000000000) {
            setTokenLiquidityPool(newValue);
        }
    };
    
    const handleTokenBurnLimitChange = (event) => {
        const newValue = parseInt(event.target.value);
        if (!isNaN(newValue) && newValue >= 0 && newValue <= 100000) {
            setTokenBurnLimit(newValue);
        }
    };
    
    const handleDestroyAfterBurnFlagChange = (event) => {
        const newValue = event.target.checked;
        setDestroyAfterBurnFlag(newValue);
        if (newValue) {
            setAssignAfterBurnFlag(false);
        }
    };
    
    const handleAssignAfterBurnFlagChange = (event) => {
        const newValue = event.target.checked;
        setAssignAfterBurnFlag(newValue);
        if (newValue) {
            setDestroyAfterBurnFlag(false);
        }
    };

    const handlePrivateKeyChange = (event) => {
        setPrivateKey(event.target.value);
    };
    
    const handleGasLimitChange = (event) => {
        setGasLimit(event.target.value);
    };

    const deployContract = async (contractABI, contractBYTECODE) => {
        setLoading(true);
        try {
            // Initialize web3 and set the provider
            const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
            // const web3 = new Web3(new Web3.providers.HttpProvider(web3Provider));
            // Set private key
            web3.eth.accounts.wallet.add(privateKey);
            // Request account access
            await window.ethereum.enable();
        
            const accounts = await web3.eth.getAccounts();
            const networkId = await web3.eth.net.getId();
        
            const deployedContract = new web3.eth.Contract(contractABI);
        
            const constructorArguments = [
                tokenName,
                tokenSymbol,
                numDecimals,
                tokenTotalSupply,
                tokenMaxMint,
                tokenLiquidityPool,
                destroyAfterBurnFlag,
                assignAfterBurnFlag,
                allowBridgingOfTokensFlag,
                tokenBurnLimit,
            ];
        
            const contract = await deployedContract
                .deploy({
                data: contractBYTECODE,
                arguments: constructorArguments,
                })
                .send({
                from: accounts[0],
                gas: gasLimit,
                });
        
            contractAddress = contract.options.address;
            setSnackbarMessage(`Contract deployed at address: ${contractAddress}`);
            setSnackbarOpen(true);        
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setSnackbarMessage("Error: " + error.message);
            setSnackbarOpen(true);
        }
      };
      

    // const deployContract = async (contractABI, contractBYTECODE) => {
    //     setLoading(true);
    //     try {
    //         // Initialize web3 and set the provider
    //         const web3 = new Web3(Web3.givenProvider || "http://localhost:8545");
    //         // Set private key
    //         web3.eth.accounts.wallet.add(privateKey);
    //         // Request account access
    //         await window.ethereum.enable();
        
    //         const accounts = await web3.eth.getAccounts();
    //         const networkId = await web3.eth.net.getId();
        
    //         const deployedContract = new web3.eth.Contract(contractABI);

    //         const constructorArguments = [
    //             tokenName,
    //             tokenSymbol,
    //             numDecimals,
    //             tokenTotalSupply, 
    //             tokenMaxMint, 
    //             tokenLiquidityPool, 
    //             destroyAfterBurnFlag, 
    //             assignAfterBurnFlag, 
    //             allowBridgingOfTokensFlag, 
    //             tokenBurnLimit,
    //         ];
        
    //         await deployedContract.deploy({
    //             data: contractBYTECODE,
    //             arguments: constructorArguments,
    //         })
    //         .send({
    //             from: accounts[0],
    //             gas: gasLimit,
    //         }, (error, transactionHash) => {
    //             if (error) {
    //             setSnackbarMessage("Error deploying contract");
    //             setSnackbarOpen(true);
    //             } else {
    //             setSnackbarMessage(`Contract deployed with transaction hash: ${transactionHash}`);
    //             setSpozzAdminContractAddress(transactionHash);
    //             setSnackbarOpen(true);
    //             }
    //         });
        
    //         setLoading(false);
    //     } catch (error) {
    //       setLoading(false);
    //       setSnackbarMessage("Error: " + error.message);
    //       setSnackbarOpen(true);
    //     }
    // };

    return (
        <>
            {loading ? (
                <div className={classes.progress}>
                    <CircularProgress />
                </div>
            ) : (
                <div>
                    <Box display="flex" flexDirection="column">
                        <Paper className={classes.paper}>
                            <TextField
                                label="Enter your Wallet's Private Key"
                                type="password"
                                value={privateKey}
                                fullWidth
                                onChange={handlePrivateKeyChange}
                            />
                        </Paper>
                        <Paper className={classes.paper}>
                            <TextField
                                label="Gas Limit"
                                type="number"
                                value={gasLimit}
                                onChange={handleGasLimitChange}
                            />
                        </Paper>
                        <Paper className={classes.paper}>
                            <TextField
                                label="Token Name"
                                value={tokenName}
                                fullWidth
                                onChange={(event) => setTokenName(event.target.value)}
                            />
                        </Paper>
                        <Paper className={classes.paper}>
                            <TextField
                                label="Token Symbol"
                                value={tokenSymbol}
                                fullWidth
                                onChange={(event) => setTokenSymbol(event.target.value)}
                            />
                        </Paper>
                        <Paper className={classes.paper}>
                            <TextField
                                label="Decimals for Spozz Club Token"
                                type="number"
                                value={numDecimals}
                                fullWidth
                                disabled={true}
                            />
                        </Paper>
                        <Paper className={classes.paper}>
                            <TextField
                                label="Total Supply"
                                type="number"
                                value={tokenTotalSupply}
                                fullWidth
                                onChange={handleTokenTotalSupplyChange}
                            />
                        </Paper>
                        <Paper className={classes.paper}>
                            <TextField
                                label="Max Mint"
                                type="number"
                                value={tokenMaxMint}
                                fullWidth
                                onChange={handleTokenMaxMintChange}
                            />
                        </Paper>
                        <Paper className={classes.paper}>
                            <TextField
                                label="Liquidity Pool"
                                type="number"
                                value={tokenLiquidityPool}
                                fullWidth
                                onChange={handleTokenLiquidityPoolChange}
                            />
                        </Paper>
                        <Paper className={classes.paper}>
                            <Switch
                                checked={destroyAfterBurnFlag}
                                onChange={handleDestroyAfterBurnFlagChange}
                                color="primary"
                            />
                            <label>Destroy After Burn</label>
                        </Paper>
                        <Paper className={classes.paper}>
                            <Switch
                                checked={assignAfterBurnFlag}
                                onChange={handleAssignAfterBurnFlagChange}
                                color="primary"
                            />
                            <label>Assign After Burn</label>
                        </Paper>
                        {destroyAfterBurnFlag || assignAfterBurnFlag ? (
                            <Paper className={classes.paper}>
                                <TextField
                                    label="Burn Limit"
                                    type="number"
                                    fullwidth
                                    value={tokenBurnLimit}
                                    onChange={handleTokenBurnLimitChange}
                                />
                            </Paper>
                        ) : (
                            <Paper className={classes.paper}>
                                <TextField
                                    label="Burn Limit"
                                    type="number"
                                    value={0}
                                    fullwidth
                                    disabled={true}
                                />
                            </Paper>
                        )}
                        <Paper className={classes.paper}>
                            <Switch
                                checked={allowBridgingOfTokensFlag}
                                onChange={(event) =>
                                    setAllowBridgingOfTokensFlag(event.target.checked)
                                }
                                color="primary"
                            />
                            <label>Allow Bridging of Tokens</label>
                        </Paper>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Paper className={classes.paper}>
                            <label>{walletInfo}</label>
                        </Paper>
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Paper className={classes.paper}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => deployContract(EthereumContractABI, EthereumContractBYTECODE)}
                            >
                                Deploy Ethereum Contract
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => deployContract(BinanceContractABI, BinanceContractBYTECODE)}
                            >
                                Deploy Binance
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => deployContract(PolygonContractABI, PolygonContracBYTECODE)}
                            >
                                Deploy Polygon Contract
                            </Button>
                        </Paper>
                    </Box>
                </div>
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                action={
                    <Button color="secondary" size="small" onClick={handleSnackbarClose}>
                        Close
                    </Button>
                }
            />
        </>
    );
};

export default SpozzClubDeploymentPage;