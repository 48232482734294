import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Divider, List, ListItem, ListItemText, Paper, Snackbar, Switch, TextField, Typography,  } from '@material-ui/core';
import { GOERLI, SEPOLIA, ETHEREUMMAINNET, BINANCEMAINNET, BINANCETESTNET, POLYGONMAINNET, POLYGONTESTNET } from './chains';
import { ethers } from 'ethers';
import EthereumContractABI from '../ABIs/SpozzClub_ABI.json';
import BinanceContractABI from '../ABIs/SpozzClubBEP20_ABI.json';
import PolygonContractABI from '../ABIs/SpozzClubPOLY20_ABI.json';
import EthereumContractBYTECODE from '../ABIs/SpozzClub_bytecode.json';
import BinanceContractBYTECODE from '../ABIs/SpozzClubBEP20_bytecode.json';
import PolygonContracBYTECODE from '../ABIs/SpozzClubPOLY20_bytecode.json';
import Web3 from 'web3';

const BigNumber = require('bignumber.js');

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(5),
    },
    walletButton: {
        marginBottom: theme.spacing(2),
    },
    inputContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        width: '100%',
        maxWidth: '500px',
        boxSizing: 'border-box',
    },
    inputField: {
        marginBottom: theme.spacing(1),
        width: '100%',
    },
    submitButton: {
        marginTop: theme.spacing(2),
    },
    listContainer: {
        marginTop: theme.spacing(2),
        width: '100%',
        maxWidth: '500px',
        backgroundColor: theme.palette.background.paper,
    },
}));

let web3Provider = '';
let ABI = '';
let BYTECODE = '';

const SpozzClubMasterDisplay = ({spozzAdminContractAddress, sc_chainname}) => {
    const classes = useStyles();
    const [address, setAddress] = useState('');
    const [contractAddress, setContractAddress] = useState('');
    const [chainId, setChainId] = useState('');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [provider, setProvider] = useState('');
    const [network, setNetwork] = useState('');
    const [contractOwner, setContractOwner] = useState('');

    const [sender, setSender] = useState("");
    const [recipient, setRecipient] = useState("");
    const [amount, setAmount] = useState("");
    const [gasAmount, setGasAmount] = useState(1500000);
    const [tokenOwner, setTokenOwner] = useState("");
    const [spender, setSpender] = useState("");
    const [vts, setVTS] = useState();
    const [vms, setVMS] = useState();
    const [vmm, setVMM] = useState();
    const [vlp, setVLP] = useState();
    const [checked, setChecked] = useState(false);
    const [account, setAccount] = useState();
    const [amtBalanceOf, setAMTBalanceOf] = useState();
    const [allowanceAMT, setAllowanceAMT] = useState();
    const [ownerAccount, setOwnerAccount] = useState();

    const connectWallet = async () => {
        setLoading(true);
        if (window.ethereum) {
            try {
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const walletAddress = await signer.getAddress();
                setAddress(walletAddress);
            } catch (error) {
                console.error(error);
            }
        } else {
            console.error('Please install MetaMask!');
        }
        setLoading(false);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleGetContractData = async () => {
        setLoading(true);
        const contract = new ethers.Contract(contractAddress, ABI, provider);
        const tempOwnerAddress = await contract.owner();
        setContractOwner(tempOwnerAddress);

        const name = await contract.name();
        const symbol = await contract.symbol();
        const decimals = await contract.decimals();
        const totalSupply = await contract.totalSupply();
        const mintedSupply = await contract.mintedSupply();
        const maxMint = await contract.maxMint();
        const destroyFlag = await contract.destroyFlag();
        const assignFlag = await contract.assignFlag();
        const specialBurnMintFlag = await contract.specialBurnMintFlag();
        const burnLimit = await contract.burnLimit();
        const liquidityPool = await contract.liquidityPool();
        setData([
            { name: 'Name: ', value: name },
            { name: 'Symbol', value: symbol },
            { name: 'Decimals (Spozz Club Token needs 0)', value: decimals },
            { name: 'Total Supply', value: totalSupply },
            { name: 'Minted Supply', value: mintedSupply },
            { name: 'Maximum Mint', value: maxMint },
            { name: 'Flag - Burn and Destroy', value: destroyFlag },
            { name: 'Flag - Burn and Assign to Owner', value: assignFlag },
            { name: 'Flag - Allows users to Bridge Spozz Club Tokens to the 3 chains', value: specialBurnMintFlag },
            { name: 'Burn Limit', value: burnLimit },
            { name: 'Liquidity Pool', value: liquidityPool },
        ]);
        setLoading(false);
        setChecked(specialBurnMintFlag);
    };

    useEffect(() => {
        async function fetchData() {
            const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
            const tempNetwork = await tempProvider.getNetwork();
            setProvider(tempProvider);
            setNetwork(tempNetwork);
            if (tempNetwork.chainId === 1) {
                ABI = EthereumContractABI;
                BYTECODE = EthereumContractBYTECODE;
            } else if(tempNetwork.chainId === 5){
                ABI = EthereumContractABI;
                BYTECODE = EthereumContractBYTECODE;
            } else if(tempNetwork.chainId === 56){
                ABI = BinanceContractABI;
                BYTECODE = BinanceContractBYTECODE;
            } else if(tempNetwork.chainId === 97){
                ABI = BinanceContractABI;
                BYTECODE = BinanceContractBYTECODE;
            } else if(tempNetwork.chainId === 137){
                ABI = PolygonContractABI;
                BYTECODE = PolygonContracBYTECODE;
            } else if(tempNetwork.chainId === 80001){
                ABI = PolygonContractABI;
                BYTECODE = PolygonContracBYTECODE;
            }else if(tempNetwork.chainId === 11155111){
                ABI = EthereumContractABI;
                BYTECODE = EthereumContractBYTECODE;
            }else{
                ABI = '';
                BYTECODE = '';
            };
        }
        fetchData();
    }, [chainId]);

    const handleTransfer = async () => {
        setLoading(true);
        try {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const tx = await contract.transfer(recipient, amount, { gasLimit: gasAmount });
            console.log(tx);
            setSnackbarMessage(`Transaction successful: ${tx.hash}`);
            setSnackbarOpen(true);        
            setLoading(false);
            setRecipient("");
            setAmount("");
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    const handleTransferFrom = async () => {
        setLoading(true);
        try {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const tx = await contract.transferFrom(sender, recipient, amount, { gasLimit: gasAmount });
            console.log(tx);
            setSnackbarMessage(`Transaction successful: ${tx.hash}`);
            setSnackbarOpen(true);        
            setLoading(false);
            setSender('');
            setRecipient("");
            setAmount("");
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    const handleApprove = async () => {
        setLoading(true);
        try {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const tx = await contract.approve( spender, amount, { gasLimit: gasAmount });
            console.log(tx);
            setSnackbarMessage(`Transaction successful: ${tx.hash}`);
            setSnackbarOpen(true);        
            setLoading(false);
            setSpender("");
            setAmount("");
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };
      
    const handleMint = async () => {
        if(address == contractOwner){
            setLoading(true);
            try {
                const signer = await provider.getSigner();
                const contract = new ethers.Contract(contractAddress, ABI, signer);
                const tx = await contract.mint(amount, { gasLimit: gasAmount });
                console.log(tx);
                setSnackbarMessage(`Transaction successful: ${tx.hash}`);
                setSnackbarOpen(true);        
                setLoading(false);
                setAmount("");
            } catch (error) {
                console.log(error);
                setSnackbarMessage(error.message);
                setSnackbarOpen(true);        
            }
        }else{
            setSnackbarMessage(`Only the Owner of the Spozz CLub Token Contract can use the mint function`);
            setSnackbarOpen(true);            
        }
        setLoading(false);
    };

    const handleBurn = async () => {
        setLoading(true);
        try {
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const tx = await contract.burn(amount,  { gasLimit: gasAmount });
            console.log(tx);
            setSnackbarMessage(`Transaction successful: ${tx.hash}`);
            setSnackbarOpen(true);        
            setLoading(false);
            setAmount("");
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    const handleWithdraw = async () => {
        setLoading(true);
        try {
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const tx = await contract.withdraw(amount,  { gasLimit: gasAmount });
            console.log(tx);
            setSnackbarMessage(`Transaction successful: ${tx.hash}`);
            setSnackbarOpen(true);        
            setLoading(false);
            setAmount("");
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };
    
    const handleEmergencyWithdraw = async () => {
        if(address == contractOwner){
            setLoading(true);
            try {
                const signer = await provider.getSigner();
                const contract = new ethers.Contract(contractAddress, ABI, signer);
                const tx = await contract.emergencyWithdraw({ gasLimit: gasAmount });
                console.log(tx);
                setSnackbarMessage(`Transaction successful: ${tx.hash}`);
                setSnackbarOpen(true);        
                setLoading(false);
            } catch (error) {
                console.log(error);
                setSnackbarMessage(error.message);
                setSnackbarOpen(true);        
            }
        }else{
            setSnackbarMessage(`Only the Owner of the Spozz CLub Token Contract can use this function`);
            setSnackbarOpen(true);            
        }
        setLoading(false);
    };

    const handleBurnLimit = async () => {
        setLoading(true);
        try {
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const tx = await contract.setBurnLimit(amount, { gasLimit: gasAmount });
            console.log(tx);
            setSnackbarMessage(`Transaction successful: ${tx.hash}`);
            setSnackbarOpen(true);        
            setLoading(false);
            setAmount("");
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    const handlePause = async () => {
        if(address == contractOwner){
            setLoading(true);
            try {
                const signer = await provider.getSigner();
                const contract = new ethers.Contract(contractAddress, ABI, signer);
                const tx = await contract.pauseContract();
                console.log(tx);
                setSnackbarMessage(`Transaction successful: ${tx.hash}`);
                setSnackbarOpen(true);        
                setLoading(false);
            } catch (error) {
                console.log(error);
                setSnackbarMessage(error.message);
                setSnackbarOpen(true);        
            }
        }else{
            setSnackbarMessage(`Only the Owner of the Spozz CLub Token Contract can use this function`);
            setSnackbarOpen(true);            
        }
        setLoading(false);
    };

    const handleResume = async () => {
        if(address == contractOwner){
            setLoading(true);
            try {
                const signer = await provider.getSigner();
                const contract = new ethers.Contract(contractAddress, ABI, signer);
                const tx = await contract.resumeContract();
                console.log(tx);
                setSnackbarMessage(`Transaction successful: ${tx.hash}`);
                setSnackbarOpen(true);        
                setLoading(false);
            } catch (error) {
                console.log(error);
                setSnackbarMessage(error.message);
                setSnackbarOpen(true);        
            }
        }else{
            setSnackbarMessage(`Only the Owner of the Spozz CLub Token Contract can use this function`);
            setSnackbarOpen(true);            
        }
        setLoading(false);
    };

    const handleBandD = async () => {
        if(address == contractOwner){
            setLoading(true);
            try {
                const signer = await provider.getSigner();
                const contract = new ethers.Contract(contractAddress, ABI, signer);
                const tx = await contract.toggleDestroyFlag();
                console.log(tx);
                setSnackbarMessage(`Transaction successful: ${tx.hash}`);
                setSnackbarOpen(true);        
                setLoading(false);
            } catch (error) {
                console.log(error);
                setSnackbarMessage(error.message);
                setSnackbarOpen(true);        
            }
        }else{
            setSnackbarMessage(`Only the Owner of the Spozz CLub Token Contract can use this function`);
            setSnackbarOpen(true);            
        }
        setLoading(false);
    };

    const handleBandA = async () => {
        if(address == contractOwner){
            setLoading(true);
            try {
                const signer = await provider.getSigner();
                const contract = new ethers.Contract(contractAddress, ABI, signer);
                const tx = await contract.toggleAssignFlag();
                console.log(tx);
                setSnackbarMessage(`Transaction successful: ${tx.hash}`);
                setSnackbarOpen(true);        
                setLoading(false);
            } catch (error) {
                console.log(error);
                setSnackbarMessage(error.message);
                setSnackbarOpen(true);        
            }
        }else{
            setSnackbarMessage(`Only the Owner of the Spozz CLub Token Contract can use this function`);
            setSnackbarOpen(true);            
        }
        setLoading(false);
    };
    
    const handleChange = async (event) => {
        setChecked(event.target.checked);
        setLoading(true);
        try {
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const tx = await contract.setSpecialBurnMintFlag(event.target.checked);
            console.log(tx);
            setSnackbarMessage(`Transaction successful: ${tx.hash}`);
            setSnackbarOpen(true);        
            setLoading(false);
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    const handleVTS = async () => {
        setLoading(true);
        try {
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const supply = await contract.viewTotalSupply();
            setVTS(supply.toNumber());
            setSnackbarMessage(`Success`);
            setSnackbarOpen(true);        
            setLoading(false);
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    const handleVMS = async () => {
        setLoading(true);
        try {
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const supply = await contract.viewMintedSupply();
            setVMS(supply.toNumber());
            setSnackbarMessage(`Success`);
            setSnackbarOpen(true);        
            setLoading(false);
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    const handleVMM = async () => {
        setLoading(true);
        try {
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const supply = await contract.viewMaxMint();
            setVMM(supply.toNumber());
            setSnackbarMessage(`Success`);
            setSnackbarOpen(true);        
            setLoading(false);
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    const handleVLP = async () => {
        setLoading(true);
        try {
            const signer = await provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const supply = await contract.viewLiquidityPool();
            setVLP(supply.toNumber());
            setSnackbarMessage(`Success`);
            setSnackbarOpen(true);        
            setLoading(false);
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    const handleBalanceOf = async () => {
        setLoading(true);
        try {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const amountRetrieved = await contract.balanceOf(account);
            setAMTBalanceOf(amountRetrieved.toNumber());
            setSnackbarMessage(`Success`);
            setSnackbarOpen(true);        
            setLoading(false);
            setRecipient("");
            setAccount("");
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };
    
    const handleGetAllowance = async () => {
        setLoading(true);
        try {
            const signer = provider.getSigner();
            const contract = new ethers.Contract(contractAddress, ABI, signer);
            const amountRetrieved = await contract.allowance(ownerAccount, spender);
            setAllowanceAMT(amountRetrieved.toNumber());
            console.log(allowanceAMT);
            setSnackbarMessage(`Success`);
            setSnackbarOpen(true);        
            setLoading(false);
            setRecipient("");
            setOwnerAccount("");
            setSpender('');
        } catch (error) {
            console.log(error);
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);        
        }
        setLoading(false);
    };

    return (
        <div className={classes.root}>
            <Button
                className={classes.walletButton}
                variant="contained"
                color="primary"
                onClick={connectWallet}
            >
                Connect to Wallet
            </Button>
            {loading ? (
                <Box mt={5}>
                <CircularProgress />
                </Box>
            ) : (
            address && (
                <Paper elevation={3} className={classes.inputContainer}>
                    <TextField
                    className={classes.inputField}
                    variant="outlined"
                    label="Smart Contract Address"
                    value={contractAddress}
                    onChange={(event) => setContractAddress(event.target.value)}
                    />
                    <TextField
                        className={classes.inputField}
                        variant="outlined"
                        label="Chain ID"
                        value={chainId}
                        onChange={(event) => setChainId(event.target.value)}
                    />
                    <Button
                        className={classes.submitButton}
                        variant="contained"
                        color="primary"
                        onClick={handleGetContractData}
                    >
                        Get Data
                    </Button>
                </Paper>
            )
            )}
            {data.length > 0 && (
                <List className={classes.listContainer}>
                    {data.map((item) => (
                        <ListItem key={item.name}>
                            <ListItemText primary={item.name} secondary={item.value.toString()} />
                        </ListItem>
                    ))}
                </List>
            )}
            <Box m={2}>
                <Paper>
                    <Typography variant="subtitle1" style={{ fontWeight: 'bold', color: '#1A237E', fontSize: 'larger' }}>Functions</Typography>
                    <Box p={2}>
                        <Typography>Mint</Typography>
                        <TextField
                            label="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Typography variant="body2" style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>This can be increased as well as decreased, the '1500000' is just a default value</Typography>
                        <TextField
                            label="Gas Limit Modifier (use if not enough gas error)"
                            value={gasAmount}
                            onChange={(e) => setGasAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!amount || loading}
                                onClick={handleMint}
                                >
                                {loading ? <CircularProgress size={24} /> : "Mint"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Burn</Typography>
                        <TextField
                            label="Burn amount (will only work if Burn Enabled)"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Typography variant="body2" style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>This can be increased as well as decreased, the '1500000' is just a default value</Typography>
                        <TextField
                            label="Gas Limit Modifier (use if not enough gas error)"
                            value={gasAmount}
                            onChange={(e) => setGasAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />              
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!amount || loading}
                                onClick={handleBurn}
                                >
                                {loading ? <CircularProgress size={24} /> : "Burn"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Change Burn Limit</Typography>
                        <TextField
                            label="The maximum number of tokens that can be burned (will only work if Burn Enabled)"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Typography variant="body2" style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>This can be increased as well as decreased, the '1500000' is just a default value</Typography>
                        <TextField
                            label="Gas Limit Modifier (use if not enough gas error)"
                            value={gasAmount}
                            onChange={(e) => setGasAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />              
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!amount || loading}
                                onClick={handleBurnLimit}
                                >
                                {loading ? <CircularProgress size={24} /> : "Burn"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Transfer</Typography>
                        <TextField
                            label="Recipient Address"
                            value={recipient}
                            onChange={(e) => setRecipient(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Typography variant="body2" style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>This can be increased as well as decreased, the '1500000' is just a default value</Typography>
                        <TextField
                            label="Gas Limit Modifier (use if not enough gas error)"
                            value={gasAmount}
                            onChange={(e) => setGasAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />  
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!recipient || !amount || loading}
                                onClick={handleTransfer}
                            >
                            {loading ? <CircularProgress size={24} /> : "Transfer"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Transfer From Sender to Recipient</Typography>
                        <TextField
                            label="Sender Address"
                            value={sender}
                            onChange={(e) => setSender(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Recipient Address"
                            value={recipient}
                            onChange={(e) => setRecipient(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Typography variant="body2" style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>This can be increased as well as decreased, the '1500000' is just a default value</Typography>
                        <TextField
                            label="Gas Limit Modifier (use if not enough gas error)"
                            value={gasAmount}
                            onChange={(e) => setGasAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!sender || !recipient || !amount || loading}
                                onClick={handleTransferFrom}
                            >
                            {loading ? <CircularProgress size={24} /> : "TransferFrom"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Allow another to spend on your behalf</Typography>
                        <Typography variant="body2" style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>If you are using a function on the master contract to perform a transaction, the master contract's address should be placed in the 'Spender's Address'</Typography>
                        <TextField
                            label="Spender's Address"
                            value={spender}
                            onChange={(e) => setSpender(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            label="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Typography variant="body2" style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>This can be increased as well as decreased, the '1500000' is just a default value</Typography>
                        <TextField
                            label="Gas Limit Modifier (use if not enough gas error)"
                            value={gasAmount}
                            onChange={(e) => setGasAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!spender || !amount || loading}
                                onClick={handleApprove}
                            >
                            {loading ? <CircularProgress size={24} /> : "Approve an Amount"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Withdraw</Typography>
                        <TextField
                            label="Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Typography variant="body2" style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>This can be increased as well as decreased, the '1500000' is just a default value</Typography>
                        <TextField
                            label="Gas Limit Modifier (use if not enough gas error)"
                            value={gasAmount}
                            onChange={(e) => setGasAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!amount || loading}
                                onClick={handleWithdraw}
                                >
                                {loading ? <CircularProgress size={24} /> : "Withdraw to connected Wallet"}
                            </Button>
                        </Box>
                    </Box>
                    <Divider style={{ backgroundColor: 'black', height: '2px' }} />
                    <Box p={2}>
                        <Typography><b>Alternative View Total Supply</b></Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleVTS}
                                >
                                {loading ? <CircularProgress size={24} /> : "View Total Supply"}
                            </Button>
                            {vts >= 0 && (
                                <Typography style={{ marginTop: '10px' }}>
                                    Total Supply: <b>{vts}</b>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography><b>Alternative View Minted Supply</b></Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleVMS}
                                >
                                {loading ? <CircularProgress size={24} /> : "View Minted Supply"}
                            </Button>
                            {vms >= 0 && (
                                <Typography style={{ marginTop: '10px' }}>
                                    Total Supply: <b>{vms}</b>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography><b>Alternative View Maximum Mintable Ceiling</b></Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleVMM}
                                >
                                {loading ? <CircularProgress size={24} /> : "View Mximum Mintable Supply"}
                            </Button>
                            {vmm >= 0 && (
                                <Typography style={{ marginTop: '10px' }}>
                                    Maximum Mintable Supply: <b>{vmm}</b>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography><b>Alternative View Liquidity Pool</b></Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleVLP}
                                >
                                {loading ? <CircularProgress size={24} /> : "View Liquidity Pool"}
                            </Button>
                            {vlp >= 0 && (
                                <Typography style={{ marginTop: '10px' }}>
                                    Liquidity Pool: <b>{vlp}</b>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography><b>Get Balance Of an Account as recorded in the Master Spozz Club Contract</b></Typography>
                        <Box mt={2}>
                            <TextField
                                label="Account Address to get the balance of"
                                value={account}
                                onChange={(e) => setAccount(e.target.value)}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleBalanceOf}
                                >
                                {loading ? <CircularProgress size={24} /> : "Retrieve Balance"}
                            </Button>
                            {amtBalanceOf >= 0 && (
                                <Typography style={{ marginTop: '10px' }}>
                                    Balance: <b>{amtBalanceOf}</b>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography><b>Show how much an owner has approved another 'spender' to use on their behalf</b></Typography>
                        <Box mt={2}>
                            <TextField
                                label="Account Address of the owner"
                                value={ownerAccount}
                                onChange={(e) => setOwnerAccount(e.target.value)}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                label="Account Address of the spender"
                                value={spender}
                                onChange={(e) => setSpender(e.target.value)}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleGetAllowance}
                                >
                                {loading ? <CircularProgress size={24} /> : "Retrieve Allowance"}
                            </Button>
                            {allowanceAMT >= 0 && (
                                <Typography style={{ marginTop: '10px' }}>
                                    Balance: <b>{allowanceAMT}</b>
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Divider style={{ backgroundColor: 'black', height: '2px' }} />
                    <Box p={2}>
                        <Typography style={{ color: 'red' }}>EMERGENCY Withdraw (Only the Contract Owner can execute ths)</Typography>
                        <Typography variant="body2" style={{ fontStyle: 'italic', color: 'red', fontSize: 'smaller' }}>This can be increased as well as decreased, the '1500000' is just a default value</Typography>
                        <TextField
                            label="Gas Limit Modifier (use if not enough gas error)"
                            value={gasAmount}
                            onChange={(e) => setGasAmount(e.target.value)}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                        />
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleEmergencyWithdraw}
                                >
                                {loading ? <CircularProgress size={24} /> : "EMERGENCY WITHDRAW ALL ASSETS"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Pause the Contract for maintenance (Only the Contract Owner can execute ths)</Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handlePause}
                                >
                                {loading ? <CircularProgress size={24} /> : "Pause the Contract for Maintenance"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Resume the Contract after maintenance (Only the Contract Owner can execute ths)</Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleResume}
                                >
                                {loading ? <CircularProgress size={24} /> : "Resume the Contract after Maintenance"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Toggle the Burn and Destroy Flag (Only the Contract Owner can execute ths)</Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleBandD}
                                >
                                {loading ? <CircularProgress size={24} /> : "Toggle Burn and Destroy Flag"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Toggle the Burn and Assign to the Owner Flag (Only the Contract Owner can execute ths)</Typography>
                        <Box mt={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={loading}
                                onClick={handleBandA}
                                >
                                {loading ? <CircularProgress size={24} /> : "Toggle Burn and Assign Flag"}
                            </Button>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography>Toggle the Flag that allows Briding of Tokens (Only the Contract Owner can execute ths)</Typography>
                        <Box mt={2}>
                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                color="primary"
                            />
                            <p>{checked ? 'Set Bridging ON' : 'Set Bridging OFF'}</p>
                        </Box>
                    </Box>
                </Paper>
            </Box>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
                action={
                    <Button color="secondary" size="small" onClick={handleSnackbarClose}>
                        Close
                    </Button>
                }
            />
        </div>
    );
}

export default SpozzClubMasterDisplay;
