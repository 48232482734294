import React, { useState, useEffect } from 'react';
import Web3Modal from "web3modal";
import axios from 'axios';
import { ethers } from "ethers";
import { Box } from '@mui/system';
import { CssBaseline, AppBar, Toolbar, Typography, Drawer, Divider, List, ListItemButton, ListItemText, Button } from '@mui/material';
import StepsPage from './StepsPage';
import DeploymentPage from './SpozzClubDeploymentPage';
import MasterDisplay from './SpozzClubMasterDisplay';

const Web3 = require("web3");

const AppAuthenticated = () => {
  const drawerWidth = 340;
  const [walletConnected, setWalletConnected] = useState(false);
  const [instruction, setInstruction] = useState("Waiting for connection with wallet...");
  const [initialAccount, setInitialAccount] = useState('');

  const [mySelection, setMySelection] = useState(0);
  const [spozzConnectWallet, setSpozzConnectWallet] = useState(true);
  const [spozzInitialDeploy, setSpozzInitialDeploy] = useState(true);
  const [spozzInitialConnect, setSpozzInitialConnect] = useState(false);

  const [deployingAccountAddress, setDeployingAccountAddress] = useState('Spozz Club Token NOT deployed yet');

  const [spozzAdminContractAddress, setSpozzAdminContractAddress] = useState("Spozz Club Token NOT deployed yet");

  const [sc_networkname, setSC_NetworkName] = useState('NONE');
  const [sc_networkId, setSC_NetworkId] = useState(0);
  const [sc_chainname, setSC_ChainName] = useState('NONE');
  const [sc_initialdeploy, setSC_InitialDeploy] = useState('NOT DEPLOYED YET');

  const [sc_aaa, setSC_aaa] = useState('NO ADMINSTRATION ACCOUNT');

  useEffect(() => {
    if(sc_networkId === 1 ? setSC_NetworkName('Ethereum Mainnet')
    : (sc_networkId === 5) ? setSC_NetworkName('Goerli Testnet')
    : (sc_networkId === 56) ? setSC_NetworkName('Binance Mainnet')
    : (sc_networkId === 97) ? setSC_NetworkName('Binance Testnet')
    : (sc_networkId === 137) ? setSC_NetworkName('Polygon Testnet')
    : (sc_networkId === 80001) ? setSC_NetworkName('Polygon Testnet')
    : (sc_networkId === 11155111) ? setSC_NetworkName('Sepolia Testnet')
    : setSC_NetworkName('Not in the approved list')
    ); 
    setSC_ChainName(sc_networkId);
  }, [sc_networkId]);

  useEffect(() => { setMySelection(0); setSpozzInitialDeploy(false); }, []);

  function Steps_to_follow(props) {
    return (
      <StepsPage />
    )
  }
  function InitialDeployment(props){
    return (
      <DeploymentPage setSC_aaa={setSC_aaa} setSC_NetworkId={setSC_NetworkId} setSpozzAdminContractAddress={setSpozzAdminContractAddress}/>
    )
  };

  function SCAdminApp(props){
    return (
      <MasterDisplay spozzAdminContractAddress={spozzAdminContractAddress} sc_chainname={sc_chainname} />
    )
  };


  function MultiTokenWallet(props){

  };

  function TimeLockedWallet(props){

  };

  function VestingMaster(props){

  };

  function StakingMaster(props){

  };

  function VestingUsers(props){

  };

  function StakingUsers(props){
 
  };

  function SpozzAirDrop(props){

  };

  return (
    <div>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position='fixed'
          sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
          <Toolbar>
            <Typography variant="h6" noWrap component="div">
              Spozz Club Administrator's Dashboard for Testing
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'box-border'
            }
          }}
          variant='permanent'
          anchor='left'>
          <Toolbar sx={{ bgcolor: "#000000" }}><p style={{ color: '#FFD700', fontWeight: 'bold', fontSize: '24px', paddingTop: '12px' }}>SPOZZ CLUB</p></Toolbar>
          <Divider />
          <List>
            {!spozzInitialDeploy //&& !spozzConnectWallet
              ? <ListItemButton >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#d1ebff', color: '#000088' }}>To deploy the Initial Contract</Typography>} onClick={() => setMySelection(1)} />
                </ListItemButton>
              : <ListItemButton disabled>
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#009dff', color: '#0000cc' }}>To deploy the Initial Contract</Typography>} />
                </ListItemButton>
            }
            {spozzInitialDeploy
              ? <ListItemButton disabled>
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#009dff', color: '#0000cc' }}>Spozz Club Master Contract Display</Typography>} />
                </ListItemButton>
              : <ListItemButton >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#d1ebff', color: '#000088' }}>Spozz Club Master Contract Display</Typography>} onClick={() => setMySelection(2)} />
                </ListItemButton>
            }
            <Divider sx={{ bgcolor: "black" }}/>
            {spozzInitialDeploy
              ? <ListItemButton >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#009dff', color: '#0000cc' }}>Create/Deploy an empty Spozz Club MultiToken Wallet</Typography>} />
                </ListItemButton>
              : <ListItemButton disabled >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#d1ebff', color: '#000088' }}>Create/Deploy an empty Spozz Club MultiToken Wallet</Typography>} onClick={() => setMySelection(3)} />
                </ListItemButton>
            }
            {spozzInitialDeploy
              ? <ListItemButton >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#009dff', color: '#0000cc' }}>Create/Deploy a Spozz Club TimeLocked Wallet (Used as a Team Wallet or otherwise)</Typography>} />
                </ListItemButton>
              : <ListItemButton disabled >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#d1ebff', color: '#000088' }}>Create/Deploy a Spozz Club TimeLocked Wallet (Used as a Team Wallet or otherwise)</Typography>} onClick={() => setMySelection(4)} />
                </ListItemButton>
            }
            <Divider sx={{ bgcolor: "black" }}/>
            {spozzInitialDeploy
              ? <ListItemButton >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#009dff', color: '#0000cc' }}>Spozz Club Vesting Contract - Master</Typography>} />
                </ListItemButton>
              : <ListItemButton disabled >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#d1ebff', color: '#000088' }}>Spozz Club Vesting Contract - Master</Typography>} onClick={() => setMySelection(5)} />
                </ListItemButton>
            }
            {spozzInitialDeploy
              ? <ListItemButton >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#009dff', color: '#0000cc' }}>Spozz Club Staking Contract - Master</Typography>} />
                </ListItemButton>
              : <ListItemButton disabled >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#d1ebff', color: '#000088' }}>Spozz Club Staking Contract - Master</Typography>} onClick={() => setMySelection(6)} />
                </ListItemButton>
            }
            <Divider sx={{ bgcolor: "black" }}/>
            {spozzInitialDeploy
              ? <ListItemButton >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#009dff', color: '#0000cc' }}>Spozz Club Vesting Contract - Users</Typography>} />
                </ListItemButton>
              : <ListItemButton disabled >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#d1ebff', color: '#000088' }}>Spozz Club Vesting Contract - Users</Typography>} onClick={() => setMySelection(7)} />
                </ListItemButton>
            }
            {spozzInitialDeploy
              ? <ListItemButton >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#009dff', color: '#0000cc' }}>Spozz Club Staking Contract - Users</Typography>} />
                </ListItemButton>
              : <ListItemButton disabled >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#d1ebff', color: '#000088' }}>Spozz Club Staking Contract - Users</Typography>} onClick={() => setMySelection(8)} />
                </ListItemButton>
            }
            <Divider sx={{ bgcolor: "black" }}/>
            {spozzInitialDeploy
              ? <ListItemButton >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#009dff', color: '#0000cc' }}>Spozz Club Token Airdrop</Typography>} />
                </ListItemButton>
              : <ListItemButton disabled >
                  <ListItemText primary={<Typography sx = {{ fontSize: '14px', p:1 }} style={{ backgroundColor: '#d1ebff', color: '#000088' }}>Spozz Club Token Airdrop</Typography>} onClick={() => setMySelection(9)} />
                </ListItemButton>
            }
          </List>
          <Divider />
          <Typography variant="h6" noWrap component="div" sx = {{ pt: 1, pb: 1, pr: 1, pl: 1, textAlign: 'center', color: '#0000aa' }}>
            Wallet Address
          </Typography>
          <Typography noWrap component="div" sx = {{ pb: 1, textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
            {sc_aaa}
          </Typography>
          <Typography noWrap component="div" sx = {{ textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
            Chain Selected is: {sc_chainname}
          </Typography>
          <Typography noWrap component="div" sx = {{ pt: 1, pb: 1, textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
            Network Selected is: {sc_networkname}
          </Typography>
          <Divider />
          <Divider />
          <Typography variant="h6" noWrap component="div" sx = {{ pt: 1, pb: 1, pr: 1, pl: 1, textAlign: 'center', color: '#0000aa' }}>
            Spozz Club Account #
          </Typography>
          <Typography noWrap component="div" sx = {{ textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
            {spozzAdminContractAddress}
          </Typography>
        </Drawer>
        <Box
          component='main'
          // sx={{ flexGrow: 1, bgcolor: 'background-default', p: 3 }}>
          sx={{ flexGrow: 1, bgcolor: '#F0F8FF', p: 3 }}>
          <Toolbar />
          {/* {myBool ? <InitialDeployment toggleBool={() => setCount(count + 1)} /> : <SpozzAdmin /> } */}
          <span>
            {mySelection === 0 ? <StepsPage />
            : mySelection === 1 ? <InitialDeployment />
            : mySelection === 2 ? <SCAdminApp />
            : mySelection === 3 ? <MultiTokenWallet />
            : mySelection === 4 ? <TimeLockedWallet />
            : mySelection === 5 ? <VestinMaster />
            : mySelection === 6 ? <StakingMaster />
            : mySelection === 7 ? <VestingUsers />
            : mySelection === 8 ? <StakingUsers />
            : <SpozzClubAirDrop /> }
            <div></div>
          </span>
        </Box>
      </Box>
      {/* {window.ethereum ?
        (walletConnected ?
          console.log("Wallet connected")
          : console.log(instruction))
        : "Metamask or other EIP-1102 / EIP-1193 compliant wallet not found."
      } */}
    </div>
  );
};

export default AppAuthenticated;





          // {spozzAdminAccountAddress !== "No account connected" && spozzInitialConnect === false
          //   // ? <Typography noWrap component="div" sx = {{ pt: 1, textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
          //   //   Your Chain Name is: {spozzName}
          //   // </Typography>
          //   // : <Typography noWrap component="div" sx = {{ pt: 1, textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
          //   //   Your Chain Name is: No Chain Name
          //   // </Typography>
          // }
          // {spozzAdminAccountAddress !== "No account connected" && spozzInitialConnect === false
          //   // ? <Typography noWrap component="div" sx = {{ pt: 1, pb: 1, textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
          //   //   Your Chain ID is: {spozzChainID[0]}
          //   // </Typography>
          //   // : <Typography noWrap component="div" sx = {{ pt: 1, pb: 1, textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
          //   //   Your Chain ID is: No Chain ID
          //   // </Typography>
          // }

          // {sc_aaa !== "NO ADMINSTRATION ACCOUNT"
          //   ? <Typography noWrap component="div" sx = {{ pt: 1, pb: 1,textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
          //     The Spozz Token Variant selected is: {spozzVariantSelected}
          //   </Typography>
          //   : <Typography noWrap component="div" sx = {{ pt: 1, pb: 1, textAlign: 'center', color: '#0000ff', fontSize: '12px' }}>
          //     The Spozz Token Variant selected is: NONE
          //   </Typography>
          // }
