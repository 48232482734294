import { Box, Divider, Paper, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: '#000',
  },
  typographyBlue: {
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: '#00A',
  },
  listItem: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    color: '#000',
  },
  header: {
    height: '140px',
    backgroundColor: '#f5f5f5',
    color: '#000',
    fontSize: '18px',
    fontWeight: 'bold',
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  divider: {
    height: '3px',
  }
}));

const StepsPage = () => {
  const classes = useStyles();
  return (
    <>
      <div>
        <Box display="flex" flexDirection="column">
          <Paper className={classes.paper}>
            <Typography className={classes.header}>This page is for informational purposes, it will direct you through the process of testing the various Spozz Club contracts - at this time during the completion of the front-end of this testing suite <b>ONLY</b> the Spozz Club Token contract available through this interface - the remaining buttons <b>will be enabled</b> as the front-end is finalized</Typography>
            <br />
            <Typography className={classes.typography}><b>Step 1.</b> Click on "To deploy the initial Contract" at the left (in the drawer panel)</Typography>
            <Typography className={classes.typography}><i>Note: For a more detailed technical explanation on the various contracts and their associated fields, variables, flags, and functions. Please refer to the technical document "Spozz Club Contracts"</i></Typography>
            <Typography className={classes.typography}><b>Step 2.</b> Now that the Spozz Club Token Master Contract is deployed, click on "Spozz Club Master Contract Display" at the left, to view and have access to all of it's fields and functions</Typography>
            <Typography className={classes.typography}><b>Step 2a.</b> Click on "Connect Wallet", you will then be asked to enter the Spozz Club Master Token "Smart Contract Address" (this address was displayed in Step 1 or can be found in the lower left [side panel] indicated by "Wallet Address")</Typography>
            <Typography className={classes.typography}><b>Step 2a.</b> You will also be asked to enter the Chain ID" (this chain id can be found in the lower left [side panel] indicated by "Chain Selected is")</Typography>
            <Typography className={classes.typography}><b>Step 2b.</b> Click on "Get Data", you will then be able to see what is contained in the Spozz Club Token as well as have access to the various functions in has available to oth holders of tokens and in some cases ONLY the owner.</Typography>
            <Typography className={classes.typography}><b><i>Note: You must have connected the same wallet for both steps 1 and 2</i></b></Typography>
            <br />
            <Divider className={classes.divider} />
            <br />
            <Typography className={classes.typographyBlue}>Steps 1 and 2 will allow creation, usage, and deployment of the <b>Spozz Club Token</b></Typography>
            <br />
            <Divider className={classes.divider} />
            <br />
            <Typography className={classes.typography}><b>The following Buttons:</b></Typography>
            <ul>
              <li className={classes.listItem} >"Create/Deploy an empty Spozz Club MultiToken Wallet"</li>
              <li className={classes.listItem} >"Create/Deploy a Spozz Club TimeLocked Wallet (Used as a Team Wallet or otherwise)"</li>
              <li className={classes.listItem} >"Spozz Club Vesting Contract - Master"</li>
              <li className={classes.listItem} >"Spozz Club Staking Contract - Master"</li>
              <li className={classes.listItem} >"Spozz Club Vesting Contract - Users"</li>
              <li className={classes.listItem} >"Spozz Club Staking Contract - Users"</li>
              <li className={classes.listItem} >"Spozz Club Token Airdrop"</li>
            </ul>
            <Typography className={classes.typography}>Are disabled even though all of the supporting smart contracts are completed, the front-end is still being finalized as explained</Typography>
          </Paper>
        </Box>  
        <Box display="flex" flexDirection="column">
          <Paper className={classes.paper}>
            <Typography className={classes.typography}><b>Note 2:</b> When using the page "Spozz Club Master Contract Display", and since you will be interacting with the blockchain directly - you may need to periodically use "Connect Wallet" to reconnect to your account - this is a feature of the browser being used in conjunction with the connected wallet AND is not influenced by this test suite.</Typography>
          </Paper>
          <Paper className={classes.paper}>
            <Typography className={classes.typography}><b>Note 3:</b> When using the page "Spozz Club Master Contract Display", if you use a function you will have to press the "Get Data" button again to retrieve the newer contract's contents (the nature of a distributed app).</Typography>
          </Paper>
          <Paper className={classes.paper}>
            <Typography className={classes.typography}><b>Note 4:</b> When using the page "Spozz Club Master Contract Display", if you use a function and it fails to affect the data in the contract <b>BUT</b> there was a message in the lower left that stated it succeeded and gave transaction #. Copy that number (rule of thumb always copy it during testing)and paste it into one of the etherscan search engines for the testnet or mainnet chains you are using. It will display - under status - the reason for the failure - it is usually that the gas was too low, so you need to up it in the input field and retry the function or there was not enough balance in the wallet.</Typography>
          </Paper>
          <Paper className={classes.paper}>
            <Typography className={classes.typography}><b>Note 5:</b> And you can always message me (jorge2020softeng [AT] gmail [DOT] com), when I see it I will reply with a fix or help. No worries :)</Typography>
          </Paper>
        </Box>
      </div>
    </>
  )
};

export default StepsPage;
